/* cyrillic */
@font-face {
  font-family: '__Playfair_Display_6088de';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/0c3e316a4114a730-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Playfair_Display_6088de';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/b0d394db6b3f2e9e-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Playfair_Display_6088de';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2e4c7318067556f5-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Playfair_Display_6088de';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e62b98dbf52f4df6-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: '__Playfair_Display_6088de';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/803d1667cf8f854f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Playfair_Display_6088de';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/74b1ffaad1f441fa-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Playfair_Display_6088de';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c95fdb792c9378f4-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Playfair_Display_6088de';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5a9a8a133e60054d-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Playfair_Display_Fallback_6088de';src: local("Times New Roman");ascent-override: 97.25%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 111.26%
}.__className_6088de {font-family: '__Playfair_Display_6088de', '__Playfair_Display_Fallback_6088de';font-weight: 400
}.__variable_6088de {--font-playfair: '__Playfair_Display_6088de', '__Playfair_Display_Fallback_6088de'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_277957';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0af52dea8ac3719e-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_277957';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/eb43c931d05a48b9-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_277957';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/90d913ebe939996d-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_277957';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/41ef2aae558a0141-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_277957';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0f556d8c93684184-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_277957';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/8e0b308d369df7de-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_277957';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6e6f564fd0f03a2c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_277957';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/122f63906a34a2b1-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_277957';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/10864d548dcccb1a-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_277957';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/2d5e2bb0c7e5c7df-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_277957';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/e0ab5f1acc6f1102-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_277957';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6a0d3b47bda27a24-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_277957';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/2bfa90ee860f53c7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_277957';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/b41420708a9e334c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_277957';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_277957 {font-family: '__Inter_277957', '__Inter_Fallback_277957';font-weight: 600
}.__variable_277957 {--font-inter: '__Inter_277957', '__Inter_Fallback_277957'
}

